<template>
  <div v-if="visible" class="freeModel">
    <div class="free">
      <div style="position: relative">
        <img class="ImmediateClaim" src="../../assets/paidImg/ImmediateClaim.png" usemap="#image-map">
        <div class="title">
          <div class="name">{{serviceInfo.name}}</div>
          <div class="shortIntro" v-html="shortIntro"></div>
        </div>
        <div class="btn" @click="handleClick"></div>
        <div class="icon">
          <a-icon @click="closeModel" type="close-circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {getAuthenticationInformation, getServiceInfo, isHaveCompany, receiveService} from "@/service/authentication";
export default {
  name: "FreeModel",
  components: {
    admin_con: admin_icon,
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    isNext:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      userInfoList:{},
      serviceInfo:{},
      shortIntro:[],
    }
  },
  created() {
    this.getInformation();
  },
  methods:{
    //获取服务介绍
    async getInformation() {
      const response = await getServiceInfo('mfdjwwz');
      if (response.code === 200) {
        this.serviceInfo = response.data;
        this.shortIntro = response.data.shortIntro ? response.data.shortIntro.replace(/\n/g, '</br>'):'';
        // this.shortIntro = response.data.shortIntro ? response.data.shortIntro.split("\n"):'';
        // console.log(this.shortIntro)
      } else {
        this.$message.warning(response.message);
      }
    },
    //判断该账号有没有认证，未认证则弹出认证账号的弹窗，已认证则直接领取
    async isNexts() {
      const response = await isHaveCompany();
      if (response.code === 200) {
        if(!response.data){
          // 未认证则弹出认证账号的弹窗
          this.$store.dispatch("isOpenFreeSetMeeting",false);
          let visible = false;
          this.$emit("closeMain", visible); //子组件向父组件传值（开关）
          this.$parent.$parent.openRZ('Free'); //打开认证弹窗
          //认证成功后弹客服二维码
          // this.$store.dispatch("isOpenVoucherModel",true); // 打开优惠卷开关
        }else {
          //已认证则直接领取
          await this.postReceiveService();
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    //领取服务
    async postReceiveService() {
      let data = {
        code:'mfdjwwz',
      }
      const response = await receiveService(data);
      if (response.code === 200) {
        //打开专属客服，关闭当前弹窗
        //关闭弹窗，等下次登录时再打开
          this.$store.dispatch("isOpenFreeSetMeeting",false);
          let visible = false;
          this.$emit("closeMain", visible); //子组件向父组件传值（开关）
          this.$emit("openExclusive", true); //子组件向父组件传值（开关） //打开客服弹窗
          this.$store.dispatch("isOpenVoucherModel",true); // 打开优惠卷开关
      } else {
        this.$message.warning(response.message);
      }
    },
    handleClick() {
      this.isNexts(); //是否认证
      // this.postReceiveService();
    },
    closeModel(type){
      //关闭弹窗，等下次登录时再打开
      this.$store.dispatch("isOpenFreeSetMeeting",false);

      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）

      //关闭当前弹窗，打开 优惠卷弹窗
      if(this.isNext){
        this.$store.dispatch("isOpenVoucherModel",true);
        this.$parent.$parent.isPopUpWindows();
      }

    },
  }
}
</script>

<style scoped>
.freeModel{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0,0,0,.7);
}
.free{
  position: fixed;
  top: 2%;
  left: 35%;
}
.ImmediateClaim{
  width: 450px;
}
.btn{
  width: 181px;
  height: 53px;
  background-color: rgba(0,0,0,.0);
  position: absolute;
  bottom: 225px;
  left: 135px;
  border-radius: 50px;
  cursor: pointer;
}
.title{
  width: 100%;
  text-align: center;
  position: absolute;
  top: 180px;
}
.icon{
  position: absolute;
  bottom: 100px;
  color: white;
  font-size: 40px;
  left: 200px;
  cursor: pointer;
}
.name{
  color: #FFE220;font-size: 30px;letter-spacing: 3px;margin-bottom: 10px;
}
.shortIntro{
  color: #fde316;font-size: 12px;width: 75%;margin: auto;text-align: left;
  border: 1px solid #fde316;
  padding: 10px;
}
</style>
