<template>
  <div v-if="visible" class="freeModel">
    <div class="free">
      <div style="position: relative">
        <img class="ImmediateClaim" src="../../assets/paidImg/couponBGImg.jpg" usemap="#image-map">
        <div class="SpecialPaper">
          <svg width="380" height="100">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stop-color="#FFFAE5" />
                <stop offset="100%" stop-color="#FFEB8A" />
              </linearGradient>
            </defs>
            <text
                x="50%"
                y="50%"
                font-weight="bold"
                font-size="55"
                fill="url(#gradient)"
                stroke="#FF6800"
                stroke-width="1"
                text-anchor="middle"
                dominant-baseline="middle">
              {{discounted.name}}
            </text>
          </svg>
        <!--新人专项卷-->
        </div>
        <div class="title">
          <div class="titleImg" v-for="(item,index) in discounted.disActCouponList" :key="index">

            <img class="img" src="../../assets/paidImg/couponY.png" alt="">
<!--            <img v-if="getColor(index) === 'orange'" class="img" src="../../assets/paidImg/couponO.png" alt="">-->
<!--            <img v-if="getColor(index) === 'red'" class="img" src="../../assets/paidImg/couponR.png" alt="">-->

            <div class="title-coupon-div">
              <div style="width: 105px;">
                <div><span class="couponMoneyIcon">￥</span><span class="money">{{item.favorablePrice}}</span></div>
<!--                <div class="mark" :style="{color: getColors(index)}">满300使用</div>-->
                <div class="mark" :style="{color: '#e28700'}">满{{item.thresholdPrice}}使用</div>
              </div>
              <div style="width: 165px;font-size: 12px;">
                <div class="coupon-right-title">{{item.couponName}}</div>
                <div class="coupon-right-time" :style="{color: '#e28700'}">{{item.beginTime}}-{{item.endTime}}</div>
                <div class="coupon-right-tag">
                  <a-tag color="#ffffff" style="margin-bottom: 5px" v-for="(tag,index) in item.label" :key="index"><span class="tag-font" :style="{color: '#e28700'}">{{tag}}</span></a-tag>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="btn" @click="handleClick(discounted.id)">一键领取</div>
        <div class="kf">客服电话：8888-9999999</div>
        <div class="icon">
          <a-icon @click="closeModel" type="close-circle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {admin_icon} from "@/utils/myIcon";
import {
  getAuthenticationInformation,
  getServiceInfo,
  isHaveCompany, oneClickReceiveByDaId,
  receiveService,
  selectDiscountedActivities
} from "@/service/authentication";
import {setChannel} from "@/service/base";
export default {
  name: "CouponModel",
  components: {
    admin_con: admin_icon,
  },
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    discounted:{
      type:Object,
      default:{},
    },
  },
  data(){
    return{
      // discounted:{}
    }
  },
  created() {
    // this.getInformation();
  },
  methods:{
    //获取代金卷
    async getInformation() {
      const response = await selectDiscountedActivities();
      if (response.code === 200) {
        // this.discounted = response.data[0];
      } else {
        this.$message.warning(response.message);
      }
    },
    //判断该账号有没有认证，未认证则弹出认证账号的弹窗，已认证则直接领取
    async isNext(id) {
      const response = await isHaveCompany();
      if (response.code === 200) {
        if(!response.data){
          // 未认证则弹出认证账号的弹窗
          // 认证之后一键领取优惠卷
          this.$parent.$parent.openRZ('Voucher');
          this.closeModel();
        }else {
          //已认证则直接领取
          await this.postReceiveService(id);
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    //领取服务
    async postReceiveService(id) {
      // await setChannel('GiftCenter');
      let data = {
        id:id
      }
      const response = await oneClickReceiveByDaId(data);
      if (response.code === 200) {
        this.$message.success("领取成功！")
        //关闭当前弹窗
        this.closeModel();
      } else {
        this.$message.warning(response.message);
      }
    },
    handleClick(id) {
      this.isNext(id);
      // this.postReceiveService(id);
    },
    closeModel(){
      //关闭弹窗，等下次登录时再打开
      this.$store.dispatch("isOpenVoucherModel",false)

      let visible = false;
      this.$emit("closeMain", visible); //子组件向父组件传值（开关）
    },
    getColor(index) {
      const colors = ['yellow','orange','red'];
      return colors[index % colors.length];
    },
    getColors(index) {
      const colors = ['#e28700','#D44B1F','#DD3532'];
      return colors[index % colors.length];
    },
  }
}
</script>

<style scoped lang="scss">
.freeModel{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0,0,0,.7);
}
.free{
  position: fixed;
  top: 5%;
  left: 39%;
}
.ImmediateClaim{
  width: 380px;
}
.SpecialPaper{
  position: absolute;
  top: 28px;

}
.kf{
  width: 100%;
  position: absolute;
  bottom: 20px;
  color: white;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
}
.btn{
  width: 130px;
  height: 35px;
  line-height: 35px;
  background-color: #FEB63D;
  position: absolute;
  bottom: 85px;
  left: 125px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  /*font-weight: bold;*/
}
.title{
  width: 100%;
  height: 340px;
  //background-color: #333333;
  overflow-y: scroll;
  //text-align: center;
  position: absolute;
  top: 200px;
}
.titleImg{
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  .img{
    width: 280px;
  }
  .title-coupon-div{
    position: absolute;
    left: 55px;
    top: 10px;
    display: flex;
    color: white;
    z-index: 9;
    .couponMoneyIcon{
      font-size: 20px;font-weight: bold;
    }
    .money{
      font-size: 30px;font-weight: bold;
    }
    .mark{
      margin-bottom: 5px;font-weight: bold;letter-spacing: 1px;
    }
    .coupon-right-title{
      font-size: 16px;margin-bottom: 5px;
    }
    .coupon-right-time{
      margin-bottom: 5px;font-weight: bold;
    }
    .coupon-right-tag{
      width: 100%;padding-left: 5px;
      .tag-font{
        font-size: 10px;font-weight: 400;
      }
    }
  }
}
.icon{
  position: absolute;
  top: -5px;
  color: white;
  font-size: 40px;
  left: 335px;
  cursor: pointer;
}
.name{
  color: #FFE220;font-size: 30px;letter-spacing: 3px;margin-bottom: 10px;
}
.shortIntro{
  color: #fde316;font-size: 12px;width: 75%;margin: auto;text-align: left;
  border: 1px solid #fde316;
  padding: 10px;
}
svg {
  display: block; /* 可选，调整显示 */
}
</style>
