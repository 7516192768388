<template>
  <div style="height: auto;background-color: #fff;border: 10px solid #edeff2;">
    <a-spin :spinning="spinning">
      <div class="CCE">
        <span :style="{marginTop: '6px',width: '75px',color:variables.topTitleColor}">会议分类：</span>
        <div :style="{color:variables.topNameColor}">
          <a-button
              :class="{ liBackground:provincesStyle === 0,textAlign:'center'}"
              size="small"
              @click="provinces(0)"
              style="border: none;box-shadow: none;">全部</a-button>
          <a-button v-for="(item,index) in liveClassifyList" :key="item.id"
              style="margin: 5px;border: none;box-shadow: none;"
                    size="small"
                    :class="{ liBackground:provincesStyle === index+1,textAlign:'center'}"
                    id="provincesBtn"
                    @click="provinces(index+1,item.id)">{{item.title}}</a-button>
           <a-button
              size="small"
              @click="showModal"
              style="border: none;box-shadow: none;color:#1890ff">
              管理
            </a-button>
        </div>
        <!-- @click="provinces(0)"  :class="{ liBackground:provincesStyle === 0,textAlign:'center'}" -->
        <!-- <admin_con @click="loadList" class="iconSheZhi" type="icon-shezhi"/> -->
      </div>
      <div class="live-top">
      <div class="liveTopSty">
        <div class="live-top-input">
          <div :style="{width: '70px',color:variables.topTitleColor}">会议名称：</div>
          <a-input
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="live-top-input">
          <div :style="{width: '70px',color:variables.topTitleColor}">会议日期：</div>
          <a-range-picker
              style="width: 250px"
              v-model="year"
              @change="onChange"
              :allowClear="false"
              @ok="timeChangeOk">
<!--            <template slot="renderExtraFooter">-->
<!--              extra footer-->
<!--            </template>-->
          </a-range-picker>
          <!--        <a-range-picker @change="onChange" />-->
        </div>
        <div class="live-top-input" style="width: 30%">
          <div :style="{width: '70px',color:variables.topTitleColor}">直播状态：</div>
<!--          <a-radio-group v-model="liveStatus" button-style="solid" @change="radioChange">-->
          <a-radio-group v-model="liveStatus" button-style="solid">
            <a-radio-button value="0" @click="radioClick('0')">
              未开始
            </a-radio-button>
            <a-radio-button value="1" @click="radioClick('1')">
              进行中
            </a-radio-button>
            <a-radio-button value="2" @click="radioClick('-1')">
              已结束
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="live-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px;color: rgba(0,0,0,0.85)">重置</a-button>
        </div>
      </div>
    </div>
     <div class="titleTop">
       <div class="divSty">
         <div :style="{fontWeight: '600',color:variables.topNameColor,display:'flex'}">
           <span>会议列表</span>
           <a-alert style="height: 40px;margin-top: 5px;margin-left: 10px"  type="info" show-icon size="small" v-if="!isKefu">
             <span slot="message" style="font-weight: normal">增值服务：如需代搭建微网站、设计KV/海报、会议直播等服务，请点击 <a @click="openService">询价服务</a></span>
           </a-alert>
         </div>

         <a-button @click="addTopBtn" type="primary" style="width: 100px;margin-top: 9px"> + 新建会议</a-button>
       </div>
     </div>
      <div v-if="dataList.length === 0"><NullDataPrompt message="您还没有会议呢 ~"/></div>
<!--      数据卡片    -->
      <div v-else>
        <div class="card">
          <a-row>
            <a-col
                :span="innerWidths > 0 && innerWidths < 1920 ? 5 :4"
                :style="{marginBottom: '2%',}"
                v-for="(card,index) in dataList" :key="index" >
              <div class="colHover" :style="{width: innerWidths > 0 && innerWidths < 1920 ? '92%' :'89.2%',border:'1px solid #F5F5F5',margin: 'auto',backgroundImage:'url('+card.coverPics[0]+')',backgroundSize:'100%',backgroundRepeat:'no-repeat'}">
<!--                <div :style="{backgroundImage:'url('+card.coverPics[0]+')',height:'400px',backgroundSize:'100%',backgroundRepeat:'no-repeat'}"></div>-->
                <div style="position: relative;border-bottom: 1px solid #F5F5F5;">

                  <img v-if="card.isPublished === 1" class="rightIcon" src="../../../assets/meet/issue.png" alt="">
                  <img v-else class="rightIcon" src="../../../assets/meet/issueNo.png" alt="">

                  <div :style="{fontSize: innerWidths > 0 && innerWidths < 1920 ? '10px' :'12px',position:'absolute',top:innerWidths > 0 && innerWidths < 1920 ? '110px' :'127px'}" class="imgText">
                    {{card.beginDate}} - {{card.endDate}}
                  </div>
                   <div :style="{height: innerWidths > 0 && innerWidths < 1920 ? '135px' :'150px'}">
                     <div style="width: 100%;display: flex;justify-content: center;">
                       <!-- <img style="width: 100%;height: 160px" :src="card.coverPics[0]">-->
<!--                       <img style="width: 100%;max-height: 170px;" :src="card.coverPics[0]">-->
                     </div>
                   </div>
                </div>
                <div style="background-color: #ffffff;padding: 7px 0 0 7px">
                    <div class="titleSty">
                      <a-tag v-if="card.isPublished === 1" class="tag" :color="card.liveStatus === 0 ? '#2db7f5' : card.liveStatus === 1 ? '#FFBA69' : card.liveStatus === -1 ? '#cccccc':''">
                        {{card.liveStatus === 1?'进行中':card.liveStatus === 0?'未开始':card.liveStatus === -1?'已结束':''}}
                      </a-tag>{{card.title}}</div>
                    <div style="height: 20px;">
<!--                      {{card.beginDate}} - {{card.endDate}}-->
                      <span style="color: #a0a2a3;" v-if="card.createdById">操作人：{{card.createdName}}</span>
                      <span style="color: #44d901;" v-else>操作人：{{card.kfName}}</span>
                    </div>
                  </div>
                <div class="btn">
<!--                <div class="btn" :style="{width: innerWidths > 0 && innerWidths < 1920 ? '92%' :'88.5%'}">-->
                    <div class="fontSty" @click="linkOpen(card.tempUrl,card.id)">预览</div>
                    <div>|</div>
                    <div class="fontSty" @click="functionSet(card)">设置</div>
<!--                    <div>|</div>-->
<!--                    <div class="fontSty" @click="restBtn(card)">设置</div>-->
                    <div>|</div>
                    <div class="fontSty" @click="dataBtn(card)">统计</div>
                  <div>|</div>
                    <a-dropdown>
                      <a style="color: #b4b3b3" class="ant-dropdown-link" @click="e => e.preventDefault()">
                        更多 <a-icon type="down" />
                      </a>
                      <a-menu slot="overlay">
<!--                        <a-menu-item @click="restBtn(card)">-->
<!--                          <a href="javascript:;">编辑</a>-->
<!--                        </a-menu-item>-->

                        <a-menu-item v-if="card.isPublished" @click="NoDelBtn()">
                          <a href="javascript:;" style="color: #e0e0e0">删除</a>
                        </a-menu-item>

                        <a-menu-item v-else>
                          <a-popconfirm
                              title="是否确认删除?"
                              ok-text="是"
                              cancel-text="否"
                              @confirm="delBtn(card)"
                          >
                            <a href="#">删除</a>
                          </a-popconfirm>
                        </a-menu-item>


                      </a-menu>
                    </a-dropdown>
                  </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div style="width: 100%;" v-show="dataList.length">
          <div style="display: flex;justify-content: center">
            <a-pagination
                show-quick-jumper
                :pageSize="innerWidths > 0 && innerWidths < 1920 ? 10 : 12"
                :current="page_no"
                :total="total"
                @change="paginationChange" />
          </div>
          <div style="height: 10px"></div>
        </div>
      </div>
<!--------------------------------------对话框------------------------>
          <a-drawer
              :get-container="false"
              @close="add_cancel"
              :width="660"
              :body-style="{ paddingBottom: '80px' }"
              :visible="add_visible"
              title="新建">
        <a-form-model
            ref="content_list_Form_add"
            :model="add_Form"
            :rules="addFormRules"
            :label-col="labelColAdd"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="coverPics" label="会议KV图">
            <div style="position: relative;width: 288px;">
              <div class="upImgModel" @click="openImgModel('coverPics')">
                <div class="content" v-if="!add_Form.coverPics[0]">
                  <div class="addModel">+</div>
                  <div>
                    上传
                  </div>
                </div>
                <div>
                  <img style="width: 270px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;"
                       :src="add_Form.coverPics[0]" alt="">
                </div>
              </div>
              <a-icon v-if="add_Form.coverPics[0]"
                      @click="()=>{add_Form.coverPics = []}"
                      class="icon" type="close-circle"/>

            </div>
            <div style="color: #a1a1a1;width: 500px">注:建议尺寸1920px*1080px，大小不超过2M</div>
          </a-form-model-item>

          <a-form-model-item  prop="name" label="会议名称">
            <a-input v-model="add_Form.name" style="width: 300px;" placeholder="请输入"/>
            <span style="color: #a1a1a1;font-size: 12px;">注：会议名称最长不能超过60个汉字</span>
          </a-form-model-item>
          <a-form-model-item  prop="time" label="会议日期">
              <a-range-picker v-model="add_Form.time" style="width: 300px" @change="DateChange"/>
          </a-form-model-item>

          <a-form-model-item  prop="address">
            <template slot="label">
              <span>会议地点/描述
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{tooltipAddress}}</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </span>
            </template>
              <a-input style="width: 300px" v-model="add_Form.address" placeholder="请输入"/>
          </a-form-model-item>

          <a-form-model-item  prop="startTime">

            <template slot="label">
              <span>直播开始时间
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{tooltip}}</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </span>
            </template>
              <a-date-picker
                  style="width: 300px"
                  placeholder="请选择"
                  @change="StartChange"
                  v-model="add_Form.startTime"
                  format="YYYY-MM-DD HH:mm:ss"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
              />
          </a-form-model-item>

          <a-form-model-item  prop="columId" label="会议分类" style="width: 600px">
              <a-select
                  show-search
                  option-filter-prop="children"
                  @change="columIdChange"
                  style="width: 300px;float: left"
                  class="input"
                  v-model="add_Form.columId"
                  placeholder="请选择">
                <a-select-option
                    v-for="lives in liveClassifyList"
                    :value="lives.id">
                  {{lives.title}}
                </a-select-option>
              </a-select>
<!--              <div>-->
                <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
                <a-icon @click="loadList" class="iconStY" type="plus-square" />
<!--              </div>-->
          </a-form-model-item>

          <a-form-model-item  prop="template" label="选择模版">
              <a-select
                  show-search
                  option-filter-prop="children"
                  style="width: 300px"
                  class="input"
                  v-model="add_Form.template"
                  placeholder="请选择">
                <a-select-option
                    @click="selectTem(templates.id)"
                    v-if="templates.name !== '全部'"
                    v-for="templates in template_list"
                    :value="templates.id">
                  {{templates.name}}
                </a-select-option>
              </a-select>
          </a-form-model-item>

          <a-form-model-item label="主色值" >
            <a-input v-model="add_Form.mainColor" style="width: 300px;">
              <input
                  :value="add_Form.mainColor"
                  type="color"
                  slot="addonBefore"
                  @change="colorAdd"
                  style="outline-style: none ;border: 0; padding: 0;height: 29px">
            </a-input>
          </a-form-model-item>

          <a-form-model-item label="首页背景图">
            <div style="position: relative;width: 288px;">
              <div class="upImgModel" @click="openImgModel('homeCoverImage')">
                <div class="content" v-if="!add_Form.homeCoverImage">
                  <div class="addModel">+</div>
                  <div>
                    上传
                  </div>
                </div>
                <div>
                  <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                       :src="add_Form.homeCoverImage" alt="">
                </div>
              </div>
              <a-icon v-if="add_Form.homeCoverImage"
                      @click="()=>{add_Form.homeCoverImage = ''}"
                      class="icon" type="close-circle"/>
            </div>
            <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
          </a-form-model-item>

          <a-form-model-item label="转发封面图">
            <div style="position: relative;width: 288px;">
              <div class="upImgModel_relayImg" @click="openImgModel('relayImg')">
                <div class="content_relayImg" v-if="!add_Form.relayImg">
                  <div class="addModel_relayImg">+</div>
                  <div>
                    上传
                  </div>
                </div>
                <div>
                  <img style="width: 280px;height:288px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                       :src="add_Form.relayImg" alt="">
                </div>
              </div>
              <a-icon v-if="add_Form.relayImg"
                      @click="()=>{add_Form.relayImg = ''}"
                      class="icon" type="close-circle"/>
            </div>
            <div style="color: #a1a1a1">注:尺寸建议500*500，大小不超过500kb</div>
          </a-form-model-item>
        </a-form-model>
      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
        <a-button  @click="add_cancel">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
          确认
        </a-button>
      </div>
      </a-drawer>
      <!-- 新增对话框 -->
      <a-modal
          title="新增"
          :visible="visibleClass"
          @ok="handleOk"
          @cancel="handleCancel"
          style="width:200px;height: 130px;"
      >
        <a-form-model :model="form"  :rules="addRules" ref="ruleForm_add" style="display:flex">
          <a-form-model-item label="分类标题" prop="name" style="display:flex">
            <a-input  placeholder="请输入"   v-model="form.name"  style="display:flex;margin-top:5px;width:220px"/>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

      <!-- 管理弹窗 -->
      <a-modal v-model="modalShow" title="会议分类管理" @ok="handleSubmit" @cancel="modalCancel" width="1000px">
        <a-spin :spinning="spin">
          <!-- 头部 -->
          <div class="top">
            <span :style="{ color: variables.topTitleColor }">分类名称：</span>
            <a-input
              placeholder="请输入"
              style="width: 200px; margin-left: 5px"
              v-model="remark"
            />
            <a-button
              type="primary"
              style="margin-left: 10px; font-size: 14px"
              @click="search"
              >查询</a-button
            >
            <a-button @click="clear" style="margin-left: 20px">重置</a-button>
          </div>
          <!-- 中间 -->
          <!-- <div style="width: 100%; height: 2px; background: #edeff2"></div> -->
          <!-- 列表 -->
          <!-- <div class="liebiao">
            <div class="title">分类列表</div>
          </div> -->
          <div
            :style="{
              width: variables.space_div,
              margin: 'auto',
              paddingTop: variables.LineSpacing,
              paddingBottom: variables.LineSpacing,
            }"
          >
            <a-button type="primary" @click="add1Management">新增</a-button>
          </div>
          <div :style="{ width: variables.space_div, margin: 'auto' }">
            <a-table
              :pagination="false"
              :row-key="(record) => record.id"
              :columns="columnsAdd"
              :dataSource="dataSource"
            >
              <!-- 操作 -->
              <span slot="is_operation" slot-scope="text, record, index">
                <a
                  style="margin-right: 10px; "
                  @click="upMove(record)"
                  v-show="index === 0 ? false : true"
                  >上移</a
                >
                <a
                  style="margin-right: 10px; "
                  @click="downMove(record)"
                  v-show="index === dataSource.length - 1 ? false : true"
                  >下移</a
                >
                <a
                  style="margin-right: 10px; "
                  @click="edit(record)"
                  >编辑</a
                >
                <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="del(record)"
                >
                  <a >删除</a>
                </a-popconfirm>
              </span>
            </a-table>
            <div
              style="
                width:100%;
                margin-top: 28px;
                display: flex;
                align-items: center;
                justify-content: space-between;"
            >
            <span></span>
            <span>
              <a-pagination
                v-model="pageNo"
                :total="managetotal"
                show-less-items
                @change="handlepageNo"
              />
            </span>
            </div>
          </div>


          <!-- -----------------新增对话框 ----------------------------------->
          <a-modal
            title="新增"
            :visible="newBuildvisible"
            @ok="handleNewOk"
            @cancel="handleNewCancel"
          >
            <a-form-model
              :model="newBuildform"
              :rules="addNewRules"
              ref="ruleNewForm_add"
              style="display: flex"
            >
              <a-form-model-item label="分类标题" prop="name" style="display: flex">
                <a-input
                  placeholder="请输入"
                  v-model="newBuildform.name"
                  style="display: flex; margin-top: 5px; width: 220px"
                />
              </a-form-model-item>
            </a-form-model>
          </a-modal>

      <!---------------------- 编辑对话框------------------- -->
      <a-modal
        title="编辑"
        :visible="visible1"
        @ok="editOk"
        @cancel="editCancel"
      >
        <a-form-model
          :model="newBuildform"
          :rules="addNewRules"
          ref="ruleForm_edit"
          style="display: flex"
        >
          <a-form-model-item label="分类标题" prop="name" style="display: flex">
            <a-input
              placeholder="请输入"
              style="display: flex; margin-top: 5px; width: 220px"
              v-model="newBuildform.name"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>

        </a-spin>
      </a-modal>

          <!-- 编辑-->
          <a-drawer
              :get-container="false"
              @close="rest_cancel"
              :body-style="{ paddingBottom: '80px' }"
              width="680px"
              :visible="list_visible"
              title="编辑">
            <a-form-model
                ref="content_list_Form_rest"
                :model="list_Form"
                :rules="list_Form_rules"
                :label-col="labelColAdd"
                :wrapper-col="wrapperCol"
            >
              <a-form-model-item prop="coverPics" label="会议KV图">
                <div style="position: relative;width: 288px;">
                  <div class="upImgModel" @click="openImgModel('editCoverPics')">
                    <div class="content" v-if="!list_Form.coverPics[0]">
                      <div class="addModel">+</div>
                      <div>
                        上传
                      </div>
                    </div>
                    <div>
                      <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;"
                           :src="list_Form.coverPics[0]" alt="">
                    </div>
                  </div>
                  <div class="hoverBgc" v-if="list_Form.coverPics[0]">
                    <div class="iconDel">
                      <admin_con
                          @click="()=>{this.list_Form.coverPics = []}"
                           type="icon-shanchu1"/>
                    </div>
                  </div>
                </div>
                <div style="color: #a1a1a1">注:建议尺寸1920px*1080px,大小不超过2M</div>
              </a-form-model-item>
              <a-form-model-item prop="title" label="会议名称">
                <a-input v-model="list_Form.title" style="width: 300px;" placeholder="请输入"/>
                <div style="color: #a1a1a1;font-size: 12px">注：会议名称最长不能超过60个汉字</div>
              </a-form-model-item>
              <a-form-model-item prop="beginDate" label="会议日期">

                <div style="display: flex">
                  <a-range-picker
                      style="width: 300px"
                      @change="picher_onChange"
                      :allowClear="false"
                      :value="[moment(list_Form.beginDate, 'YYYY-MM-DD'), moment(list_Form.endDate, 'YYYY-MM-DD')]"
                      :format="'YYYY-MM-DD'"/>
                </div>
              </a-form-model-item>
              <a-form-model-item prop="address">
                <template slot="label">
              <span>会议地点/描述
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{tooltipAddress}}</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </span>
                </template>
                 <a-input style="width: 300px" v-model="list_Form.address" placeholder="请输入"/>
              </a-form-model-item>
<!--              <a-form-model-item  prop="columId" label="会议分类">-->
              <a-form-model-item label="会议分类">
                <div style="display: flex;width: 400px">
                  <a-select
                      show-search
                      option-filter-prop="children"
                      style="width: 300px;"
                      class="input"
                      v-model="list_Form.columId"
                      placeholder="请选择">
                    <a-select-option
                        v-for="liveClassify in liveClassifyList"
                        :value="liveClassify.id">
                      {{liveClassify.title}}
                    </a-select-option>
                  </a-select>
                  <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
                  <a-icon @click="loadList" class="iconStY" type="plus-square" />
                </div>
              </a-form-model-item>
              <a-form-model-item label="主色值" >
                <a-input v-model="list_Form.mainColor" style="width: 300px;">
                  <input
                      :value="list_Form.mainColor"
                      type="color"
                      slot="addonBefore"
                      @change="colorRest"
                      style="outline-style: none ;border: 0; padding: 0;height: 29px">
                </a-input>
              </a-form-model-item>
              <a-form-model-item  label="首页背景图">
                <div style="position: relative;width: 288px;">
                  <div class="upImgModel" @click="openImgModel('editHomeCoverImage')">
                    <div class="content" v-if="!list_Form.homeCoverImage">
                      <div class="addModel">+</div>
                      <div>
                        上传
                      </div>
                    </div>
                    <div>
                      <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;"
                           :src="list_Form.homeCoverImage" alt="">
                    </div>
                  </div>
                  <div class="hoverBgc" v-if="list_Form.homeCoverImage">
                    <div class="iconDel">
                      <admin_con
                          @click="()=>{list_Form.homeCoverImage = ''}"
                          type="icon-shanchu1"/>
                    </div>
                  </div>
                </div>
                <div style="color: #a1a1a1">注:宽度不能超过750px，大小不超过1M</div>
              </a-form-model-item>
              <a-form-model-item label="转发封面图">
                <div style="position: relative;width: 288px;">
                  <div class="upImgModel_relayImg" @click="openImgModel('editRelayImg')">
                    <div class="content_relayImg" v-if="!list_Form.relayImg">
                      <div class="addModel_relayImg">+</div>
                      <div>
                        上传
                      </div>
                    </div>
                    <div v-if="list_Form.relayImg">
                      <img style="width: 280px;height: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;"
                           :src="list_Form.relayImg" alt="">
                    </div>
                  </div>
                  <div class="hoverBgc_relayImg" v-if="list_Form.relayImg">
                    <div class="iconDel_relayImg">
                      <admin_con
                          @click="()=>{list_Form.relayImg = ''}"
                          type="icon-shanchu1"/>
                    </div>
                  </div>
                </div>
                <div style="color: #a1a1a1">注:尺寸建议500*500，大小不超过500kb</div>
              </a-form-model-item>
            </a-form-model>
            <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
            >
              <a-button  @click="rest_cancel">
                取消
              </a-button>
              <a-button style="margin-left: 10px" type="primary" @click="content_list_Ok">
                确认
              </a-button>
            </div>
          </a-drawer>
          <!--   链接   -->
      <a-modal
          width="800px"
          v-model="linkVisible"
          title="链接"
          :footer="null">
        <div>
          <div class="linkModal">
            <div class="title">微网站访问链接 :</div>
            <div>
              <a-input v-model="appSrc" style="width: 400px" :disabled="true"/>
            </div>
            <div style="margin-left: 10px">
              <a-icon
                  @click="copyText"
                  style="color:#45a5e6;
                  font-size: 20px;
                  cursor: pointer"
                  type="copy" />
            </div>
          </div>
          <div class="codeLink">
            <div class="title">观看链接二维码:</div>
            <div style="padding: 15px;border: 1px dashed #000;background-color: #fff;"><div id="qrCode">
              <vue-qr
                  ref="image"
                  :logo-src="logoSrc"
                  :size="150"
                  :margin="0"
                  :auto-color="true"
                  :dot-scale="1"
                  :text="appSrc" />
            </div></div>
            <div>
              <a-icon
                  @click="copyImage"
                  class="iconStys"
                  type="copy" />
            </div>
            <div>
              <a-icon
                  @click="downImg"
                  class="iconStys"
                  type="vertical-align-bottom" />
            </div>
          </div>
        </div>
      </a-modal>
      <!------------------图片对话框----------------------->
      <pictureDialog
          :info="pInfo"
          :visible="visible"
          v-on:closeMain="closeMain"
          v-on:checkList="checkList">
      </pictureDialog>

      <MessageAlert
          :visible="visibleAlert"
          v-on:closeMain="closeMainAlert"
      ></MessageAlert>
<!--      服务介绍
          免费搭建微网站服务code：mfdjwwz
          （收费）代理搭建微网站服务code：wwzdlfw   目前写死此code-->
      <LiveServiceIntro
          v-if="LiveIntroVisible"
          :visible="LiveIntroVisible"
          :serviceCode="serviceCode"
          v-on:closeMain="closeMainIntro"
          v-on:openExclusive="openExclusive"
      />



      <!--      系统公告-->
      <MessageNotifi
          :isNext="true"
          v-if="visibleMessageNotifi"
          :visible="visibleMessageNotifi"
          v-on:closeMainMessageNotifi="closeMainMessageNotifi"
      />

<!--      免费领取搭建微网站一次弹窗-->
      <FreeModel
          :isNext="true"
          :visible="FreeVisible"
          v-on:closeMain="closeMainFree"
          v-on:openExclusive="openExclusive"
      />
      <!--  优惠卷弹窗  -->
      <CouponModel
          :discounted="discounted"
          :visible="CouponVisible"
          v-on:closeMain="closeMainCoupon"
      />








      <!--  专属客服-->
      <ServiceExclusive
          v-if="visibleExclusives"
          :visible="visibleExclusives"
          v-on:closeMain="closeMainExclusives"
      />

      <!--认证-->
      <RealNameAuthentication
          :visible="visibleAuthentication"
          :isShow="isShow"
          :jurisdiction="jurisdiction"
          :isFrom="isFrom"
          v-on:closeMainRealNameAuth="closeMainRealNameAuth"
      ></RealNameAuthentication>

      <a-modal
          width="600px"
          v-model="visibleOperation"
          @cancel="cancelOperation"
          :footer="null">
        <div style="height: 100px;display: flex;justify-content: left">
          <div><a-icon style="color: #f4742f;font-size: 20px;"  type="info-circle"  /></div>
          <div style="margin-left: 5px">该会议由您的专属客服维护，是否确认交接？</div>
        </div>
        <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
          <a-button  @click="cancelOperation">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="OkOperation">
            继续操作
          </a-button>
        </div>
      </a-modal>

<!--      数据报告-->
      <DataReport
          v-if="visibleDataReport"
          :liveInfo="liveInfo"
          :selectNumber="selectId"
          :visible="visibleDataReport"
          v-on:closeMainDataReport="closeMainDataReport"
      ></DataReport>

    </a-spin>
  </div>
</template>

<script>
import {
  getManagement,  updatedManagement,
  moveManagement,
} from "../../../service/columnmanageapi";
import RealNameAuthentication from "@/components/Authentication/RealNameAuthentication";
// 引入scss变量js文件
import variables from "@/assets/base.scss";
import {
  CaseLiveNew, CaseLiveNewKF, deleteConvention,
  get_per_OfThe_one_list, liveClassify, memberAcceptanceConvention,
  put_per_OfThe_one_list_ofNumber,
  putSwitch,
  upDown
} from "@/service/MedicalConference";
import {update} from "@/utils/update";
import {subordinate} from "@/utils/subordinateList"
import moment from "moment";
import {getTemplateColorPicMeet, getTemplateTitle, postConvention, putImgs} from "@/service/medicalConference_api";
import {
  delAppPromotion,
  getAppPromotion,
  getColumnVodsList,
  postAppPromotion
} from "@/service/MedicalConference_y";
import {current_date} from '@/utils/currentDate'
import PublicSample from "@/utils/PublicSample";
import axios from "axios";
import LiveServiceIntro from "@/components/PaidView/LiveServiceIntro"
const columns = [
  {
    title: '会议名称',
    dataIndex: 'title',
    key: 'title',
    width:'35%',
    ellipsis: true,
  },
  {
    title: '会议地址',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: '会议日期',
    dataIndex: 'beginDate',
    key: 'beginDate',
    width:'20%',
    scopedSlots: { customRender: 'beginDate' },
  },
  {
    title: '是否发布',
    dataIndex: 'isPublished',
    key: 'isPublished',
    scopedSlots: { customRender: 'isPublished' },
  },{
    title: '平台',
    dataIndex: 'domainCode',
    key: 'domainCode',
    width:'10%',
    scopedSlots: { customRender: 'domainCode' },
  },
  {
    title: '功能设置',
    dataIndex: 'functionSet',
    key: 'functionSet',
    scopedSlots: { customRender: 'functionSet' },
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    width:'10%',
    scopedSlots: { customRender: 'system' },
  },
];
// 在需要生成二维码的文件中引入比如qrCode.vue
import VueQr from 'vue-qr';
import {AddManagement} from "@/service/columnmanageapi";
import PictureDialog from "@/components/Material/pictureDialog";
import NullDataPrompt from "@/components/NullDataPrompt";
import MessageAlert from "@/components/Authentication/MessageAlert";
import {
  addReportPb,
  getAuthenticationInformation,
  isHaveNoComService,
  isLqMfFw, oneClickReceiveByDaId,
  receiveService, selectDiscountedActivities, selectNotReadAllCount
} from "@/service/authentication";
import {admin_icon} from "@/utils/myIcon";
import FreeModel from "@/components/PaidView/FreeModel";
import CouponModel from "@/components/PaidView/CouponModel";
import ServiceExclusive from "@/components/PaidView/ServiceExclusive";
import DataReport from "@/components/meet/DataReport";
import MessageNotifi from "@/components/meet/MessageNotifi";
export default {
  components:{
    MessageNotifi,
    DataReport,
    admin_con: admin_icon,
    MessageAlert,
    NullDataPrompt,
    PublicSample,
    PictureDialog,
    VueQr,
    LiveServiceIntro,
    CouponModel,
    FreeModel,
    ServiceExclusive,
    RealNameAuthentication
  },
  data(){
    return{
      discounted:{},
      visibleDataReport:false,
      visibleMessageNotifi:false,
      selectId:1,
      liveInfo:{},
      // 管理 弹窗
      visible1:false,
      spin:false,
      remark: "",
      pageNo: 1,
      newBuildvisible:false,
      newBuildform: {
        name: "",
        code: "1",
      },
      addNewRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[^\s]+[\s]*.*$/i.test(value) == false) {
                callback(new Error("请输入正确的名称"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      pageSize: 10,
      dataSource: [],
      managetotal: 0,
      modalShow:false,
      addform: {},
      columnsAdd: [
        {
          title: "序号",
          // width: "17%",
          key: "num",

          customRender: (text, record, index) =>
            `${(this.pageNo - 1) * this.pageSize + (index + 1)}`,
        },

        {
          title: "名称",
          dataIndex: "title",
          // width: "24%",
          key: "title",
        },

        {
          title: "创建人",
          dataIndex: "createdByName",
          // width: "20%",
          key: "createdByName",
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          // width: "23%",
          key: "createdTime",
        },
        {
          title: "操作",
          dataIndex: "operation",
          // width: "30%",
          scopedSlots: { customRender: "is_operation" },
          key: "operation",
        },
      ],





      //点击功能是否进行下一步
      isNextFunction:true,
      isNextFunctionRow:{},
      isNextFunctionName:'',
      isShow: {},
      jurisdiction: true,
      visibleAuthentication:false,
      visibleOperation:false,
      serviceCode:'',
      FreeVisible:false,
      CouponVisible:false,
      visibleExclusives:false,
      LiveIntroVisible:false,
      visibleAlert:false,
      variables,
      visible:false,
      pInfo:true,
      openModelType:null,
      picList:[],
      innerWidths:null,
      propList:{
        visible:false,
        code:null,
      },
      add_visible:false,
      add_Form:{
        coverPics:[],
        name:'',
        time:[],
        address:'',
        startTime:'',
        columId:undefined,
        template:undefined,
        mainColor:'#45A5E6',
        homeCoverImage:'',
        relayImg:'',
      },
      liveClassifyList:[
        {
          id:0,
          title:'默认分类'
        }
      ],
      addFormRules:{
        coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' },{ min: 1, max: 60, message: '会议名称最长不能超过60个汉字', trigger: 'blur' },],
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],

        startTime: [{ required: true, message: '请选择', trigger: 'change' }],

        columId: [{ required: true, message: '请选择', trigger: 'change' }],
        template: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      year:[undefined,undefined],
      yearShowOne:false,
      size: 'default',
      promotion_visible:false,
      labelColAdd: { span: 6},
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      titleData:'',
      dataList:[],
      columns,
      page_no:1,
      // page_size:8,
      total:null,
      dateFormat: 'YYYY-MM-DD ',
      spinning:false,
      promotion_form:{
        isBeginImage:0,
        beginImageDetail:[],
        beginImageTime:null,
        isBannerShow:0,
        bannerImage:'',
      },
      promotion_rules: {
        beginImageDetail: [{ required: true, message: '请上传', trigger: 'blur' }],
        beginImageTime: [{ required: true, message: '请输入', trigger: 'blur' }],
        bannerImage: [{ required: true, message: '请上传', trigger: 'blur' }],
      },
      listId:'',
      list_visible:false,
      list_Form:{
        title:"",
        address:"",
        beginDate:'',
        endDate:'',
        mainColor:'#45A5E6',
        coverPics:[],
        homeCoverImage:'',
        // sortTime:'',
        relayImg:'',
        columId:undefined,
      },
      list_Form_rules: {
        title: [{ required: true, message: '请输入', trigger: 'blur' },{ min: 1, max: 60, message: '会议名称最长不能超过60个汉字', trigger: 'blur' },],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        beginDate: [{ required: true, message: '请选择', trigger: 'blur' }],
        // columId: [{ required: true, message: '请选择', trigger: 'change' }],
        coverPics: [{ required: true, message: '请上传', trigger: 'blur' }],
        // sortTime: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      domainCode:'',
      template_list:[],
      templateListUrl:'',
      optionValue:'',
      yun_subordinate_select:subordinate(),
      promotionsVisible:false,
      appColumns:[
        {
          title: 'APP首页',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      appData:[],
      columnListApp:[],
      promotionId:null,
      promotionSpin:false,
      tooltip:'用于首页的直播倒计时',
      tooltipAddress:'显示在转发之后的消息摘要处',
      provincesStyle:0,
      columId:null,
      liveStatus:'',
      status0:1,
      status1:1,
      status_1:1,
      linkVisible:false,
      logoSrc:"",
      appSrc:"",
      visibleClass:false,
      form:{},
      addRules:{
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      userInfoList:{},
      isKefu:null,
      conferenceCode:'',
      isFrom:'',
    }
  },
  created() {
    const userInfo = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
    this.isKefu = userInfo.isKefu;
    this.innerWidths = window.innerWidth; //屏幕宽度

    this.getLiveList();
    this.liveClassifyRow();
    //  加载模板列表
    this.getTemList();
    this.getInformation();

    setTimeout(()=>{
      // this.getIsLqMfFw();
      this.isPopUpWindows();
    },500)

  },
  computed:{
    // userInfoList(){
    //   return this.$store.state.userInfoList;
    // }

    //是否可以弹系统公告弹窗
    enableMessagePrompt(){
      return this.$store.state.enableMessagePrompt;
    },
    //获取系统公告未读条数
    notReadAllCountXT(){
      return this.$store.state.notReadAllCountXT;
    },
    //是否可以弹 免费领取代搭建会议
    isOpenFreeSetMeeting(){
      return this.$store.state.isOpenFreeSetMeeting;
    },
    //是否可以弹 优惠卷弹窗
    isOpenVoucherModel(){
      return this.$store.state.isOpenVoucherModel;
    }


  },
  methods:{




    /**
     * 查询是否弹窗
     *
     * 登录成功后首次进入该页面，登录时设置 enableMessagePrompt 为true
     *     1，首次检查是否符合打开系统通知弹窗
     *
     *         弹窗 -----有未读系统通知  notReadAllCountXT 不为 0
     *             -----关闭弹窗时设置  isOpenFreeSetMeeting  为true    enableMessagePrompt 为false
     *
     *       不弹窗 -----无未读系统通知  notReadAllCountXT 为 0
     *             -----设置  isOpenFreeSetMeeting  为true  enableMessagePrompt 为false
     *
     *             2,首次检查是否符合打开免费领取代搭建会议弹窗
     *                  ---- 查询用户是否领取过
     *                  弹窗  ---- 未领取过
     *                       ---- 关闭弹窗时设置 isOpenVoucherModel 为true   isOpenFreeSetMeeting 为false
     *
     *                 不弹窗 ---- 一领取过
     *                       ---- 设置  isOpenVoucherModel  为true  isOpenFreeSetMeeting 为false
     *
     *                       3,关闭代金卷时设置  isOpenVoucherModel 为false
     *
     * @returns {Promise<void>}
     */
    async isPopUpWindows() {
      if (this.enableMessagePrompt) {  // 允许系统通知

        //  判断是否打开系统通知
        if (this.notReadAllCountXT) { //有未读的系统通知
          this.visibleMessageNotifi = true;
        }else {
          //关闭 系统通知
          this.$store.dispatch("enableMessagePrompt",false);
          //打开 免费领取搭建微网站
          this.$store.dispatch("isOpenFreeSetMeeting",true);
        }

      }

      if (this.isOpenFreeSetMeeting) { //允许 免费领取搭建微网站
        //  判断是否打开免费领取代搭建会议
        const response = await isLqMfFw();
        if (response.code === 200) {
          if (!response.data) { //未领取过
            this.serviceCode = 'mfdjwwz';
            this.FreeVisible = true;
            await this.postAddReportPb('CONVENTION_HOME_PAGE');
          }else { //已经领取过
            //关闭 免费领取搭建微网站
            this.$store.dispatch("isOpenFreeSetMeeting",false);
            //打开 优惠/代金卷弹窗
            this.$store.dispatch("isOpenVoucherModel",true);
          }
        }
      }

      if(this.isOpenVoucherModel){ //允许 优惠卷弹窗

        // 判断是否打开优惠/代金卷弹窗
        const response = await selectDiscountedActivities();
        if (response.code === 200) {
          this.discounted = response.data[0];
          if(this.discounted.disActCouponList.length){ //若有优惠卷
            this.CouponVisible = true;
          }else {
            //关闭 优惠/代金卷弹窗
            this.$store.dispatch("isOpenVoucherModel",false)
          }
        } else {
          this.$message.warning(response.message);
        }
      }

    },

    //一键领取代金卷
    //领取服务
    async postReceiveVoucher() {
      let data = {
        id:this.discounted.id
      }
      const response = await oneClickReceiveByDaId(data);
      if (response.code === 200) {
        this.$message.success("领取成功！")
        this.$store.dispatch("isOpenVoucherModel",false) //关闭代金卷弹窗
      } else {
        this.$message.warning(response.message);
      }
    },


    //查询用户是否领取过免费搭建微网站服务
    async getIsLqMfFw() {
      const response = await isLqMfFw();
      if (response.code === 200) {
        if (!response.data) { //未领取过
          this.serviceCode = 'mfdjwwz';
          this.FreeVisible = true;

          if(!this.isKefu){
            await this.postAddReportPb('CONVENTION_HOME_PAGE');
          }
        }
      } else {
        this.$message.warning(response.message);
      }
    },



    // 删除
    del(record) {
      updatedManagement({
        id: record.id,
        title: record.title,
        code: record.code,
        delStatus: 1,
        type: "meeting",
      }).then((res) => {
        if (res.code == 200) {
          this.load();
          this.liveClassifyList = [
          {
            id:0,
            title:'默认分类'
          }
        ];
          this.liveClassifyRow();
          this.$message.success("删除成功");
        } else {
          this.$message.warning("删除失败");
        }
      });
    },
    // 编辑确定
    editOk() {
      this.$refs.ruleForm_edit.validate(async (valid) => {
        if (valid) {
          updatedManagement({
            id: this.addform.id,
            title: this.newBuildform.name,
            code: this.addform.code,
            delStatus: 0,
            type: "meeting",
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.visible1 = false;
              this.load();
              this.liveClassifyList = [
                {
                  id:0,
                  title:'默认分类'
                }
              ];
              this.liveClassifyRow();
              this.$refs.ruleForm_edit.resetFields();
              this.newBuildform.name = "";
            } else {
              this.$message.warning("编辑失败");
            }
          });
        }
      });
    },
    // 编辑取消
    editCancel() {
      this.visible1 = false;
      this.newBuildform.name = "";
      this.newBuildform.code = "";
      this.load();
      this.$refs.ruleForm_edit.resetFields();
      this.spin = false;
    },
    // 打开编辑
    edit(record) {
      this.newBuildform.name = record.title;
      this.addform = record;
      this.visible1 = true;
    },
    //上移
    upMove(record) {
      moveManagement("up", record.id, "meeting").then((res) => {
        if (res.code == 0) {
          this.load();
          this.liveClassifyList = [
            {
              id:0,
              title:'默认分类'
            }
          ];
          this.liveClassifyRow();
          this.$message.success("上移成功");
        } else {
          this.$message.warning("上移失败");
        }
      });
    },
    // 下移
    downMove(record) {
      moveManagement("down", record.id, "meeting").then((res) => {
        if (res.code == 0) {
          this.load();
          this.liveClassifyList = [
            {
              id:0,
              title:'默认分类'
            }
          ];
          this.liveClassifyRow();
          this.$message.success("下移成功");
        } else {
          this.$message.warning("下移失败");
        }
      });
    },
    handlepageNo(pageNo, pageSize) {
      this.load();
    },
    handleNewCancel(){
      this.newBuildvisible = false;
    },
    // 新增确定
    handleNewOk() {
      this.spin = true;
      this.$refs.ruleNewForm_add.validate(async (valid) => {
        if (valid) {
          let form = {
            title: this.newBuildform.name,
            type: "meeting",
          };
          AddManagement(form).then((res) => {
            if (res.code == 200) {
              this.spin = false;
              this.load();
              this.liveClassifyList = [
                {
                  id:0,
                  title:'默认分类'
                }
              ];
              this.liveClassifyRow();
              this.$message.success("新增成功");
              this.newBuildvisible = false;
              this.newBuildform.name = "";
              this.$refs.ruleNewForm_add.resetFields();
            } else {
              this.$message.warning("新增失败");
              this.load();
              this.$refs.ruleNewForm_add.resetFields();
            }
          });
        }
      });
    },
    // 打开新增
    add1Management() {
      if (!this.userInfoList.id) {
        this.visibleAlert = true;
      } else {
        this.newBuildvisible = true;
        this.newBuildform.name = "";
      }
    },
    search() {
      this.pageNo = 1;
      this.load();
    },
    // 获取全部数据类型
    load() {
      this.spin = true;
      getManagement({
        type: "meeting",
        title: this.remark,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.dataSource = res.data;
        this.managetotal = res.count;
        this.spin = false;
      });
    },
    clear() {
      (this.remark = "",this.pageNo = 1), this.load();
    },
    showModal(){
      this.modalShow = true
      this.remark = ''
      this.load()
    },
    handleSubmit(){
      this.modalShow = false
    },
    modalCancel(){
      this.modalShow = false
    },

    async openService() {
      this.serviceCode = 'wwzdlfw'; //服务咨询
      this.LiveIntroVisible = true;

      if(!this.isKefu){
        await this.postAddReportPb('CONVENTION_HOME_PAGE');
      }
    },
    async getInformation() {
      const response = await getAuthenticationInformation();
      if (response.code === 200) {
        this.userInfoList = response.data;
      } else {
        this.$message.warning('name',response.message);
      }
    },
    closeMain(val) {
      this.visible = val;
    },
    closeMainAlert(val) {
      this.visibleAlert = val;
    },
    closeMainIntro(val) {
      this.LiveIntroVisible = val;
    },
    //埋点
    async postAddReportPb(location) {
      let data = {
        "serviceCode":this.serviceCode, //浏览商品code值
        // "browseTime":this.seconds, //浏览时间
        "location":location //浏览位置
      }
      await addReportPb(data);
    },
    closeMainFree(val) {
      this.FreeVisible = val;
    },
    closeMainCoupon(val) {
      this.CouponVisible = val;
    },
    closeMainRealNameAuth(val) { //关闭认证弹窗
      this.visibleAuthentication = val;

      //  在关闭领取免费搭建一次后打开认证弹窗关闭认证弹窗后   --打开优惠卷弹窗
      if(this.isOpenVoucherModel){
        this.isPopUpWindows();
      }
    },
    //领取服务
    async postReceiveService() {
      let data = {
        code:'mfdjwwz',
      }
      const response = await receiveService(data);
      if (response.code === 200) {
        //领取成功后弹窗客服二维码
        this.$store.dispatch("isOpenFreeSetMeeting",false); // 打开优惠卷开关
        this.$store.dispatch("isOpenVoucherModel",true); // 打开优惠卷开关
        this.openExclusive();
      } else {
        this.$message.warning(response.message);
      }
    },
    openRZ(type) {
      this.isFrom = type;
      // type  Free 免费领取一次搭建服务
      let row = this.userInfoList;
      if (row.companyStatus === 1) {
        //待审核
      } else {
        //判断身份
        let companyStatusDW;
        if (row.memberStatus === 2) {
          //企业
          companyStatusDW = 2;
          this.jurisdiction = true;
        } else {
          this.jurisdiction = false;
          companyStatusDW = row.memberStatus;
        }
        this.isShow = {
          companyStatusDW: companyStatusDW,
          id: row.id,
          companyStatus: row.companyStatus,
          memberStatus: row.memberStatus,
        };
        this.visibleAuthentication = true;
      }
    },
    closeMainExclusives(val) { //关闭专属客服
      this.visibleExclusives = val;
      //  在关闭领取免费搭建一次后打开专属客服关闭客服弹窗   --打开优惠卷弹窗
      if(this.isOpenVoucherModel){
        this.isPopUpWindows();
      }
    },
    closeMainDataReport(val) {
      this.visibleDataReport = val;
    },
    closeMainMessageNotifi(val) {
      this.visibleMessageNotifi = val;
      this.numberEntries();
    },
    //获取系统公告未读条数
    async numberEntries() {
      const response = await selectNotReadAllCount();
      if (response.code === 200) {
        await this.$store.dispatch("notReadAllCountXT", response.data);
      } else {
        this.$message.warning("name", response.message);
      }
    },
    openExclusive(){ //关闭免费领取一次搭建的弹窗
      this.visibleExclusives = true;
    },
    checkList(val) {
      this.picList = val[0].url;
      let type = this.openModelType;
      if(type === 'coverPics'){ // 封面图
        this.add_Form.coverPics[0] = this.picList;
      }else if(type === 'homeCoverImage'){ //背景图
        this.add_Form.homeCoverImage = this.picList;
      }else if(type === 'relayImg'){ //转发封面图
        this.add_Form.relayImg = this.picList;
      }else if(type === 'editCoverPics'){ //修改-封面图
        this.list_Form.coverPics[0] = this.picList;
      }else if(type === 'editHomeCoverImage'){ //修改-背景图
        this.list_Form.homeCoverImage = this.picList;
      }else if(type === 'editRelayImg'){ //修改-转发封面图
        this.list_Form.relayImg = this.picList;
      }
    },
    //可以看到src并非url，而是base64字符串。base64转Blob：
    base64ToBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //点击复制二维码
    copyImage() {
      let container = document.getElementById('qrCode').childNodes[0];
      let myBlob = this.base64ToBlob(container.getAttribute('src'));

      navigator.clipboard.write([
          new window.ClipboardItem({
                [myBlob.type]: myBlob
          })
      ]);

      this.$message.success("图片已复制到剪贴板！")
    },
    copyText() {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.appSrc;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('文本已复制到剪贴板!')
      //移除input标签
      document.body.removeChild(input)
    },
    downImg(){
      console.log("??", this.appSrc )
      let container = document.getElementById('qrCode').childNodes[0];

      let blob = this.base64ToBlob(container.getAttribute('src'));

      let aLink = document.createElement('a');

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = '文件名';
      aLink.href = URL.createObjectURL(blob);
      aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
    },
    provinces(index,id){
      this.provincesStyle = index;
      this.columId = id;
      this.getLiveList();
    },
    openSample(code){
      // code加上时间防止重复
      this.propList = {
        visible:true,
        code:code+'202304031442',
      }
    },
    //获取会议列表
    //客服和用户接口不一致
    async getLiveList() {
      this.spinning = true;
      let byId = this.$route.query.memberId;
      let data;
      let response;
      if(this.isKefu){ //客服
        data = {
          title:this.titleData,
          columId:this.columId,
          liveStatus:this.liveStatus,
          dateBegin:this.year[0],
          dateEnd:this.year[1],
          page_no:this.page_no,
          byId:byId, //用户id
          page_size:this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
        }
        response = await CaseLiveNewKF(data)
      }else if(!this.isKefu){ //用户
        data = {
          title:this.titleData,
          columId:this.columId,
          liveStatus:this.liveStatus,
          dateBegin:this.year[0],
          dateEnd:this.year[1],
          page_no:this.page_no,
          page_size:this.innerWidths > 0 && this.innerWidths < 1920 ? 10 : 12,
        }
        response = await CaseLiveNew(data)
      }
      if(response.code === 0){
        this.dataList = response.data.rows;
        this.total = response.data.count;
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false;

    },
    async liveClassifyRow() {
      let data = {
        pageNo:1,
        pageSize: 9999999,
        type:'meeting'
      }
      const response = await liveClassify(data)
      if(response.code === 0){
        // this.liveClassifyList = response.data;
        let list = response.data;
        for (let i=0;i<list.length;i++){
          this.liveClassifyList.push(list[i]);
        }
      }else {
        this.$message.warning(response.message)
      }
      // this.spinning = false
    },
    handleOk(){
      this.$refs.ruleForm_add.validate(valid=> {
        if (valid) {
          let data = {
            title: this.form.name,
            type:'meeting'
          }
          this.postClass(data)
          this.form.name = '';
          this.visibleClass = false;
        }
      })
    },
    loadList(){
      this.visibleClass = true;
    },
    handleCancel(){
      this.visibleClass = false;
    },
    async postClass(data) {
      const response = await AddManagement(data)
      if (response.code === 200) {
        this.$message.success("新增成功！");
        this.liveClassifyList = [
          {
            id:0,
            title:'默认分类'
          }
        ];
        await this.liveClassifyRow();
      } else {
        this.$message.warning(response.message)
      }
    },
    dataBtn(row){ //跳转数据界面
      this.liveInfo = row;
      this.visibleDataReport = true;
      // this.$router.push({path:'dataStatistics',query:{code:row.conferenceCode}});
      // this.$store.dispatch("isShowNav", false);
      // // console.log("看看row",row)
      // this.$store.commit('dataAnalysisId',row.conferenceCode)
      // //保存会议创建时间
      // this.$store.dispatch("statisticsLiveCreated", row.createdTime);
    },
    restBtn(row){
      this.isNextFunctionName = '设置';
      this.isNextFunctionRow = row;
      this.ChangeOperation(row);
      if(this.isNextFunction){
        this.restBtnNext(row);
      }
    },
    async delBtn(row) {
      const response = await deleteConvention(row.conferenceCode)
      if (response.code === 200) {
        this.$message.success("删除成功！");
        //删除成功刷新列表
        await this.getLiveList()
      } else {
        this.$message.warning(response.message)
      }
    },
    NoDelBtn(){
      this.$message.warning('会议已发布，无法删除！')
    },
    restBtnNext(row){
      this.listId = row.id
      this.list_Form = {
        title:row.title,
        address:row.address,
        beginDate:row.beginDate,
        endDate:row.endDate,
        mainColor:row.mainColor?row.mainColor:'#45A5E6',
        coverPics:row.coverPics,
        homeCoverImage:row.homeCoverImage,
        // sortTime:row.sortTime,
        relayImg:row.relayImg,
        columId:row.columId,
        conferenceCode:row.conferenceCode,
        domainCode:row.domainCode,
      }
      this.list_visible = true
    },
    //点击设置和功能时，判断客服点击不进行任何操作，客户点击自己创建的会议不进行任何提示，客户点击客服创建弹窗提示
    ChangeOperation(row){
      this.conferenceCode = row.conferenceCode;
      if(!this.isKefu){ //用户操作
        if(!row.createdById){ //当用户id为空时，编辑客服创建的会议提示
          this.visibleOperation = true;
          this.isNextFunction = false; //不进行下一步
        }
      }
    },
    cancelOperation(){
      this.visibleOperation = false;
      this.isNextFunction = true;
    },
    async OkOperation() {
      let data = {
        "conferenceCode":this.conferenceCode
      }
      const response = await memberAcceptanceConvention(data)
      if (response.code === 200) {
        //关闭弹窗
        this.cancelOperation();
        if(this.isNextFunctionName === '设置'){
          this.restBtnNext(this.isNextFunctionRow);
        }else if(this.isNextFunctionName === '功能'){
          this.functionSetNext(this.isNextFunctionRow);
        }
      }
    },
    content_list_Ok(){
      this.$refs.content_list_Form_rest.validate(async valid => {
        if (valid) {
          let form = this.list_Form
          let data = {
            "conferenceCode":form.conferenceCode,//会议编码
            "address": form.address,//会议地址
            "beginDate": form.beginDate,//会议日期-开始
            // "conventionHead": "冯明霞",//
            "coverPics": form.coverPics,//封面图
            "domainCode": "yichimeeting",//写死传过来
            "endDate": form.endDate,//会议日期-结束
            "homeCoverImage": form.homeCoverImage,//背景图
            "mainColor": form.mainColor,//主色值
            // "sortTime": form.sortTime,//直播开始时间
            // "tempUrl": "https://yichimeeting-mp.yichimeeting.com/conference",//选择模板的链接
            "title": form.title,//会议标题
            "relayImg": form.relayImg,//转发封面图
            "columId": form.columId//会议所属分类id
          }
          const response = await putSwitch(data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            //关闭
            this.list_visible = false
            //  刷新
            await this.getLiveList()
          } else {
            this.$message.warning(response.message)
          }
        } else {
          return false;
        }
      });
    },
    rest_cancel(){
      this.list_visible = false;
      this.$refs.content_list_Form_rest.resetFields();
      this.getLiveList()
    },
    async radioChange() {
      this.page_no = 1;
      await this.getLiveList();
    },
    radioClick(val){
      if(val === '0'){
        if(this.status0 === 1){
          this.liveStatus = val;
          this.status0++;
        }else {
          this.liveStatus = '';
          this.status0 = 1;
        }
        this.status1 = 1;
        this.status_1 = 1;
      }else if(val === '1'){
        if(this.status1 === 1){
          this.liveStatus = val;
          this.status1++;
        }else {
          this.liveStatus = '';
          this.status1 = 1;
        }
        this.status0 = 1;
        this.status_1 = 1;
      }else if(val === '-1'){
        if(this.status_1 === 1){
          this.liveStatus = val;
          this.status_1++;
        }else {
          this.liveStatus = '';
          this.status_1 = 1;
        }
        this.status1 = 1;
        this.status0 = 1;
      }
      this.page_no = 1;
      this.getLiveList();

    },
    seachBtn(){
      this.getLiveList();
    },
    resetBtn(){
      this.provincesStyle = 0;
      this.titleData = '';
      this.columId = '';
      this.liveStatus = '';
      this.page_no = 1;
      this.year = [];
      this.getLiveList()
    },
    paginationChange(page_no,page_size){
      this.page_no = page_no;
      this.getLiveList()
    },
    functionSet(row){
      this.isNextFunctionName = '功能';
      this.isNextFunctionRow = row;
      this.ChangeOperation(row);
      if(this.isNextFunction) {
        this.$store.dispatch("meetCode", row.conferenceCode);
        this.$store.dispatch("isShowIconTitle", row.isShowIconTitle ? 1 : 0);
        this.$store.dispatch("isOpenCountdown", row.isOpenCountdown ? 1 : 0);
        if(this.$route.query.memberId){
          this.$router.push({path: '/featureManagement', query: {id: row.id,memberId:this.$route.query.memberId}})
        }else {
          this.$router.push({path: '/featureManagement', query: {id: row.id}})
        }

        this.$store.dispatch("isShowNav", false);
      }
    },
    functionSetNext(row){
        this.$store.dispatch("meetCode", row.conferenceCode);
        this.$store.dispatch("isShowIconTitle", row.isShowIconTitle ? 1 : 0);
        this.$store.dispatch("isOpenCountdown", row.isOpenCountdown ? 1 : 0);
        this.$router.push({path: '/featureManagement', query: {id: row.id}})
        this.$store.dispatch("isShowNav", false);
    },
    linkOpen(link,id){
      // console.log(link+'&convention_id='+id)
      this.appSrc = link+'&convention_id='+id;
      this.linkVisible = true;
    },

    //公用上移下移
    async upDown(convention_id,action) {
      const response = await upDown(convention_id,action)
      if (response.code === 0) {
        this.$message.success("操作成功！")
      //  刷新
        await this.getLiveList()
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    columIdChange(val){
      this.add_Form.columId = val;
    },
    upBtn(id){
      let action = 'up'
      this.upDown(id,action)
    },
    downBtn(id){
      let action = 'down'
      this.upDown(id,action)
    },
    async switchBtn(id,isOpen) {
      let data = {
        "isPublished":isOpen,
      }
      const response = await putSwitch(data)
      if (response.code === 0) {
        this.spinning = true
        this.$message.success("操作成功！")
        //  刷新
        await this.getLiveList()
        this.spinning = false
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    //获取单个详情
    async getOneList(id) {
      const response = await get_per_OfThe_one_list(id)
      if (response.code === 0) {
        let form = this.promotion_form
        form.isBeginImage = response.data.isBeginImage
        if(response.data.beginImageDetail){
          form.beginImageDetail = response.data.beginImageDetail
        }
        form.beginImageTime = response.data.beginImageTime
        form.isBannerShow = response.data.isBannerShow
        form.bannerImage = response.data.bannerImage
      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    promotion_Switch_btn(isOpen){
      this.promotion_form.isBeginImage = isOpen
    },
    openTwo_Switch_btn(isOpen){
      this.promotion_form.isBannerShow = isOpen
    },
    promotion_Btn(id){
      this.listId = id
      this.promotion_visible = true
      this.getOneList(id)
    },
    //修改公用
    async restGong(data) {
      const response = await put_per_OfThe_one_list_ofNumber(this.listId, data)
      if (response.code === 0) {
        this.$message.success("修改成功!")
      //  刷新
        await this.getOneList(this.listId)

      } else {
        this.$message.warning("失败!", response.message)
      }
    },
    promotion_Submit(){
      this.$refs.promotion_ruleForm_form_two.validate(valid => {
        if (valid) {
          let form = this.promotion_form
          let data = {
            "isBeginImage":form.isBeginImage,
            "beginImageTime": form.beginImageTime,
            "beginImageDetail": form.beginImageDetail,
            "isBannerShow":form.isBannerShow,
            "bannerImage":form.bannerImage
          }
          this.promotion_visible = false
          this.restGong(data)
          this.promotion_form.isBeginImage = null;
          this.promotion_form.beginImageDetail = [];
          this.promotion_form.beginImageTime = '';
          this.promotion_form.isBannerShow = 0;
          this.promotion_form.bannerImage = '';
        } else {
          this.$message.warning("有空~~")
          return false;
        }
      });
    },
    async promotion_upPosterCover() {
      let inputDOM = this.$refs.promotion_descImageUrl.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          // this.promotion_form.beginImageDetail[0] = image.data.url
          this.$set(this.promotion_form.beginImageDetail,0,image.data.url)
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('promotion_descImageUrl_id').value = null
    },
    async promotion_upPosterCover_beginImage() {
      let inputDOM = this.$refs.promotion_descImageUrl_beginImage_two.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.promotion_form.bannerImage = image.data.url
          this.$message.success("上传成功")

        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('promotion_descImageUrl_beginImageId').value = null
    },
    colorAdd(e){
      this.add_Form.mainColor = e.target.value
    },
    colorRest(e){
      this.list_Form.mainColor = e.target.value
    },
    startTimeChange(data){
      this.list_Form.sortTime = moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    moment,
    async list_Form_upPosterCover() {
      let form = this.list_Form
      let _this = this
      let inputDOM = this.$refs.list_Form_upPosterCover_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 2097152) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  // form.coverPics[0] = image.data.url
                  _this.$set(form.coverPics,0,image.data.url)
                  _this.$message.success("上传成功")

                } else {
                  _this.$message.warning(image.message)
                }
              } else {
                _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于2M的图片！")
      }
      document.getElementById('list_Form_upPosterCoverId').value = null
    },
    async list_Form_back() {
      let _this = this
      let form = this.list_Form
      let inputDOM = this.$refs.list_Form_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 750) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.homeCoverImage = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning(image.message)
                }
              } else {
                _this.$message.warning("注:宽度不能超过750px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('list_Form_backId').value = null
    },
    addTopBtn(){
      this.getInformation();
      sessionStorage.removeItem('pageParams');
      //账号类型 0未认证 1个人认证 2企业认证
      // if(this.userInfoList.memberStatus !== 0){
      //   this.$store.dispatch("isShowNav", false); //导航栏
      //   this.$router.push({path:'meeting'});
      // }else {
      //   this.visibleAlert = true;
      // }
      if (!this.userInfoList.id && !this.isKefu) { //未认证且不是客服
        this.visibleAlert = true;
      }else {
        this.$store.dispatch("isShowNav", false); //导航栏
        if(this.$route.query.memberId){
          this.$router.push({path:'meeting',query:{memberId:this.$route.query.memberId}});
        }else {
          this.$router.push({path:'meeting'});
        }
      }

    },
    DateChange(date, dateString) {
      this.add_Form.time = dateString
    },
    picher_onChange(value, dateString){
      this.list_Form.beginDate = dateString[0]
      this.list_Form.endDate = dateString[1]
    },
    StartChange(date, dateString){
      this.add_Form.startTime = dateString;
    },
    async add_upPosterCover() {
      let form = this.add_Form;
      let _this = this;
      let inputDOM = this.$refs.add_upPosterCover_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 2097152) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  // this.add_Form.coverPics[0] = image.data.url
                  _this.$set(form.coverPics,0,image.data.url)
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning("上传失败")
                }
              } else {
                _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于2M的图片！")
      }
      document.getElementById('add_upPosterCoverId').value = null
    },
    delCover(){
      this.add_Form.coverPics = [];
    },
    delCoverRest(){
      this.list_Form.coverPics = []
    },
    async add_back() {
      let form = this.add_Form;
      let _this = this
      let inputDOM = this.$refs.add_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 750) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  _this.add_Form.homeCoverImage = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning("上传失败")
                }
              } else {
                _this.$message.warning("注:宽度不能超过750px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('add_back_id').value = null
    },
    async delImg(type) {
      if (type === 'add_Form') {
        this.add_Form.homeCoverImage = ''
      } else if (type === 'list_Form') {
        //修改的删除是将list中的图片删除
        let id = this.listId*1
        const image = await putImgs(id)
        if (image.code === 200) {
          this.$message.success("图片删除成功~")
        } else {
          this.$message.warning(image.message)
        }
        this.list_Form.homeCoverImage = ''
      }
    },
    async add_content_list_Ok() {
      this.$refs.content_list_Form_add.validate(async valid => {
      if (valid) {
        let form = this.add_Form
        const data = {
          "address": form.address,//会议地址
          "beginDate": moment(form.time[0]).format('YYYY-MM-DD'),//会议日期-开始
          // "conventionHead": "冯明霞",
          "coverPics":  form.coverPics,//封面图
          "domainCode": 'yichimeeting',//写死传过来
          "endDate": moment(form.time[1]).format('YYYY-MM-DD'),//会议日期-结束
          "homeCoverImage": form.homeCoverImage,//背景图
          "mainColor": form.mainColor,//主色值
          "sortTime": form.startTime, //直播开始时间
          "tempUrl": this.templateListUrl, //选择模板的链接
          "title": form.name,//会议标题
          "relayImg": form.relayImg,//转发封面图
          "columId": form.columId,//会议所属分类id
        }
        await this.post_convention(data)
        this.add_cancel()
        //  刷新列表
        await this.getLiveList()
      } else {
        return false;
      }
    });
    },
    add_cancel(){
      //  关闭对话框
      this.add_visible = false
      this.$refs.content_list_Form_add.resetFields();
      this.add_Form.time = [];
      this.add_Form.homeCoverImage = '';
      this.add_Form.relayImg = '';
      this.add_Form.startTime = '';
      this.add_Form.mainColor = '#45A5E6';
    },
  //  获取列表 --- 模板列表
    async getTemList() {
      const Info = await getTemplateTitle()
      if(Info.code === 0){
        // this.template_list = response.data
        for (let i = 0 ; i < Info.data.length ; i++) {
          if (Info.data[i].isOpen === 1){
            this.template_list.push({
              id: Info.data[i].id,
              isBanner: Info.data[i].isBanner,
              isBgimg: Info.data[i].isBgimg,
              isNotice: Info.data[i].isNotice,
              isOpen: Info.data[i].isOpen,
              name: Info.data[i].name
            })
          }
        }
      }
    },
    // 获取列表 --- 模板列表中的url
    async getTemList_url(id) {
      const response = await getTemplateColorPicMeet(id)
      if(response.code === 0){
        this.templateListUrl = response.data.url
      }
    },
    //新增医学会议列表
    async post_convention(data) {
      const response = await postConvention(data)
      if(response.code === 0){
        this.$message.success("新增成功~")
      }else {
        this.$message.warning(response.message)
      }
    },
    selectTem(id){
    //  通过选择的id获取url的后半截
      this.getTemList_url(id)
    },
    promotionsClose(){
      this.promotionsVisible = false
      this.appData = []
      this.promotionId = null
    },
    async AppListSwitch(isOpen, row) {
      //推广
      if(isOpen){
        let data = [
          {
            contentId: this.promotionId,//内容id
            contentType: "LIVE_METTING",//一级内容
            columnCode: row.code,//栏目
            subContentType: "CONVENTION"//子级内容
          }
        ]
        await this.postAppSwitch(data,row)
      }else { //关闭推广
        await this.delAppSwitch(this.promotionId,row.code,'app',row)
      }
    },
    async delAppSwitch(id,code,type,row) {
      let data = {
        code:code,
        type:type
      }
      const response = await delAppPromotion(id,data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("关闭成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    async postAppSwitch(data,row) {
      const response = await postAppPromotion(data)
      if (response.code === 0) {
        //更新数据
        this.appData.forEach((a,index) => {
          if (a.code === row.code) {
            this.$set(this.appData, index, a)
            return
          }
        })
        this.$message.success("推广成功~")
      } else {
        this.$message.warning(response.message)
      }
    },
    //获取栏目
    async getColumnList(id) {
      this.promotionSpin = true
      this.appData = []
      const response = await getColumnVodsList()
      if (response.code === 0) {
        this.appData = response.data
        await this.getAppList(id)
      } else {
        this.$message.warning(response.message)
      }
      this.promotionSpin = false
    },
    async promotions(list) {
        this.promotionId = list.id
        this.getColumnList(list.id)
        this.promotionsVisible = true
    },
    async getAppList(id) {
      const response = await getAppPromotion(id)
      if (response.code === 0) {
        this.columnListApp = response.data
        //对比
        let app = this.appData
        let columnList = this.columnListApp
        app.forEach((a,index) =>{
          a.is_open = columnList.some(b =>{
            return b.code === a.code
          })
          this.$set(app,index,a)
        })
      } else {
        this.$message.warning(response.message)
      }
    },
    onChange(date, dateString){
      this.year = dateString
    },
    timeChangeOk(){
      this.getLiveList()
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }

      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('surfacePlot').value = null
    },
    async surfacePlotRest() {
      let inputDOM = this.$refs.surfacePlotRest.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.list_Form.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('surfacePlotRest').value = null
    },
    openImgModel(type){
      this.visible = true;
      this.openModelType = type;
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-col-5 {
  width: 20%;
}
.ant-col-4 {
  width: 16.66666666%;
}
.liBackground {
  background: #1890ff !important;
  color: white;
}
.CCE{
  width: $space_div;
  margin: auto;
  min-height: 60px;
  padding-top: 15px;
  border-bottom: 0.5px dashed #d7d7d7;
  display: flex;
  .iconSheZhi{
    margin-top: 5px;font-size: 20px;cursor: pointer;
  }
}
.live-top{
  height: 75px;
  width: 100%;
  border-bottom: 10px solid #EDEFF2;
  padding-top: 15px;
  .liveTopSty{
    width: $space_div;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .live-top-input{
    display: flex;
    height: 35px;
    line-height: 35px;
    .input{
      width: 200px;
      //margin-left: 5px;
      //margin-right: 10px;
    }
  }
}
.titleTop{
  border-bottom: 1px solid #EDEFF2;margin-bottom: 15px;
  .divSty{
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 98%;
    height: 50px;
    line-height: 50px;
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 300px;
  height: 169px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.bgImg{
  border: #DDDDDD 2px dashed;
  width: 172px;
  min-height: 250px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.surfacePlot{
  border: #DDDDDD 2px dashed;
  width: 172px;
  min-height: 172px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.uploadImg_img{
  position: absolute;
  top: 0;
  width: 100%;height:100%;
}
.card{
  width: 100%;
  margin: auto;
  .imgText{
    height: 25px;
    line-height: 25px;
    background-color: rgba(0,0,0,.65);
    width: 100%;
    padding-left: 5px;
    color: white;
    //z-index: 9
  }
  .colHover:hover{
    box-shadow: 1px 1px 15px #d7d7d7;
  }
  .rightIcon{
    position: absolute;
    width: 75px;
    right: 0;
  }
  .btn{
    background-color: #F7F9FA;
    color: #b4b3b3;
    height: 50px;
    line-height: 50px;
    display: flex;
    cursor: pointer;
    //position: absolute;
    //top: 225px;
    justify-content: space-around;
    .fontSty:hover{
      color: #45A5E6;
    }
  }
}
.titleSty{
  font-weight: bold;
  width: 100%;
  height: 46px;
  font-size: 16px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.linkModal{
  display: flex;
  height: 50px;
  line-height: 50px;
  .title{
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }

}
.codeLink{
  display: flex;
  margin-top: 20px;
  .title{
    width: 120px;
    text-align: right;
    margin-right: 10px;
  }
  .iconStys{
    color: #45a5e6;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.iconStY{
  color:#45a5e6;
  font-size: 20px;
  margin-left: 5px;
  margin-top:3px;float: left;
}
.upImgModel{
  width: 288px;
  min-height: 162px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 50px;
    color: #929292;
    .addModel{
      font-size: 30px;
    }
  }
}
.upImgModel_relayImg{
  width: 288px;
  height: 288px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content_relayImg{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 100px;
    color: #929292;
    .addModel_relayImg{
      font-size: 30px;
    }
  }
}
.hoverBgc{
  width: 288px;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,.4);
  top:0;
  //z-index: 999;
  border-radius: 10px;
  opacity: 0; //透明度为0 不显示遮罩层
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 25px;
  .iconDel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.hoverBgc:hover{
  opacity: 1;
}
.hoverBgc_relayImg{
  width: 288px;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,.4);
  top:0;
  //z-index: 999;
  border-radius: 10px;
  opacity: 0; //透明度为0 不显示遮罩层
  display: flex;
  justify-content: center;
  justify-items: center;
  font-size: 25px;
  .iconDel_relayImg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.hoverBgc_relayImg:hover{
  opacity: 1;
}








.ant-spin-nested-loading {
  width: 100%;
  min-height: 280px;
  height: 100%;
}

.ant-table-thead > tr > th {
  font-size: 17px;
  font-weight: 550;
}
.ant-pagination {
  width: 98%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.top {
  width: $space_div;
  margin: auto;
  height: 60px;
  padding-top: 15px;
}
.liebiao {
  width: 100%;
  border-bottom: 1px solid rgb(238, 233, 233);
  .title {
    color: $topNameColor;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    width: $space_div;
    margin: auto;
  }
}
</style>
